@import ../../../../styles/helpers

.card
    display: flex
    width: 100%
    padding: 15px 40px 5px
    border-radius: 20px
    background: $n7
    text-align: center
    transition: all .2s
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +dark
        background: $n2
        box-shadow: inset 0 0 0 1px $n2
    +m
       display: block
       padding: 30px 40px 30px

.text
    display: block
    align-items: start
    margin: 0 20px
    width: 70%
    +m
       width: 100%
       margin: 0 0
       


.preview
    display: flex
    width: 30%
    justify-content: center
    align-items: center
    padding-right: 10px 
    height: 160px
    margin: 0 auto 12px
    img
        max-width: 100%
        height: auto
        border: none
        border-radius: 0
        box-shadow: none
        // background-image: url('../../../../../public/images/feat_icon_bg.png')
        background-size: 100% 100%
        background-repeat: no-repeat
    +m
       width: 100%    

.icons
    position: absolute
    top: 50%
    left: 50%
    background: $n9
    transform: translate(-50%, -50%)         
    
.title
    margin-top: 20px
    margin-bottom: 20px
    +body-bold-2
    color: #000000
    +dark
        color:$white
  

.content
    margin-bottom: none
    color: $n4