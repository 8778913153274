@import ../../styles/helpers

.footer
    position: relative
    z-index: 10
    padding: 20px 0
    box-shadow: inset 0 1px 0 $n6
    +m
        padding: 32px 0 24px
        box-shadow: inset 0 1px 0 $n6
    +dark
        box-shadow: inset 0 1px 0 $n2
        +m
            box-shadow: inset 0 1px 0 $n2
    &.wide
        box-shadow: none
        .container
            max-width: 100%
            padding: 0 20px
            +m
                padding: 0 20px

.container
    display: flex
    justify-content: space-between
    align-items: center
    +d
        padding: 0 80px
    +t
        padding: 0 60px
    +m
        justify-content: center
        display: block
        padding: 0 20px
        
        

.body
    +m
        position: relative
        padding-top: 32px

.col
    flex: 0 0 30.5%
    padding: 80px 86px 64px
    +x
        padding: 80px 64px 64px
    +d
        flex: 0 0 34%
    +t
        padding: 64px 32px 48px
    +m
        padding: 32px 0
        border-bottom: 1px solid $n6
        +dark
            border-color: $n2
    &:first-child
        display: flex
        flex: 0 0 39%
        padding-left: 0
        +d
            flex: 0 0 32%
        +m
            display: block
    &:nth-child(3)
        padding-right: 0
    &:nth-child(2),
    &:nth-child(3)
        border-left: 1px solid $n6
        +m
            border-left: none
        +dark
            border-color: $n2

.logo
    display: flex
    justify-content: center
    align-items: center
    margin-right: 32px
    +t
        margin-right: 16px
    +m
        position: relative
        z-index: 15
        margin-right: auto 
        margin-bottom: 30px 
    img
        width: 36px
        +w
           width: 42px 
        +t
            width: 30px     
            

.picDesktop
    +m
        display: none

.picMobile
    display: none
    +m
        display: block

.item
    .category
        display: none
        +m
            display: flex
            align-items: center
            margin-bottom: 0
        svg
            margin-left: auto
            fill: $n4
            transition: transform .2s
    &.active
        +m
            .category
                svg
                    transform: rotate(180deg)
            .menu
                display: flex

.menu
    display: flex
    flex-direction: column
    align-items: flex-start
    +m
        display: none
        padding-top: 40px

.link
    +button-2
    color: $n4
    transition: color .2s
    &:hover,
    &.active
        color: $n2
        +dark
            color: $n8
    &:not(:last-child)
        margin-bottom: 24px

.category
    margin-bottom: 41px
    +hairline-2

.info
    +caption-1
    p
        &:not(:last-child)
            margin-bottom: 8px
    &:not(:last-child)
        margin-bottom: 24px

.foot
    
    display: flex
    padding: 10px 0 5px     
    +dark
        border-color: none
    +m 
       display: block 
       align-items: center   

.copyright
    display: flex
    justify-content: center
    margin-right: 24px
    +body-2
    color: $n4
    +m
        margin: 0 24px 10px
        margin-bottom: 20px

.socials
    display: flex
    justify-content: center
    


.social
    display: flex
    background-color: rgba(0, 0, 0, 0.1)
    border-radius: 100%
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 30px
    height: 30px
    transition: all .2s
    +dark
        color: $white
        background-color: $n2
    svg
        fill: $d1
        +dark
            fill: $n9
        transition: fill .2s

    &:hover 
        background-color: rgba(0, 0, 0, 0.3)
        svg
            fill: $d1
            +dark
                fill: $n9
    &:not(:last-child)
        margin-right: 24px
