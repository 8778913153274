@import ../../../styles/helpers

.download
    position: relative
    display: flex
    align-items: center
    min-height: 908px
    padding: 136px 0
    +d
        min-height: 764px
        padding: 112px 0
    +m
        min-height: auto
        padding: 20px 0 64px

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 300px
    +m
        max-width: 100%  

.title
    margin-bottom: 16px
    +m
        text-align: center 


.info
    margin-bottom: 64px
    +body-2
    color: $n4
    +m
        margin-bottom: 48px
        text-align: center 

.item
    display: flex
    align-items: center
    &:hover
        .icon
            &:after
                opacity: 1
                visibility: visible
    &:not(:last-child)
        margin-bottom: 32px
        padding-bottom: 32px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.icon
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 56px
    height: 56px
    margin-right: 24px
    border-radius: 50%
    background: $n2
    img
        max-width: 24px
    &:after
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 50%
        background: $p1 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' fill='none' viewBox='0 0 14 9'%3E%3Cpath fill-rule='evenodd' d='M9.091.265a1 1 0 0 1 1.413.057l3.231 3.5a1 1 0 0 1 0 1.357l-3.231 3.5a1 1 0 0 1-1.47-1.357L10.716 5.5H1a1 1 0 1 1 0-2h9.716L9.034 1.678A1 1 0 0 1 9.091.265z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat  50% 50% / 14px auto
        visibility: hidden
        opacity: 0
        transition: all .2s

.details
    flex-grow: 1

.subtitle
    +body-1
    color: $n2
    +dark
        color: $n8

.content
    color: $n4

.bg
    position: absolute
    top: 50%
    right: calc(50% - 750px)
    width: 800px
    transform: translateY(-50%)
    pointer-events: none
    +d
        right: calc(50% - 620px)
        width: 620px
    +t
        right: calc(50% - 520px)
        width: 540px
    +m
        position: static
        transform: translateY(0)
        width: auto
        margin-right: -32px
    img
        width: 80%
        hieght: 50%