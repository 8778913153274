@import ../../../styles/helpers

.section
    margin-bottom: 20px
    margin-top: 20px

.container
    +d
        padding: 0 40px
    +m
        padding: 0 32px

.head
    max-width: 455px
    margin: 0 auto 64px
    text-align: center
    +m
        margin-bottom: 20px

.title
    margin-bottom: 20px
    align-text: center


.wrapper
    display: flex
    justify-content: space-between
    color: $white
    flex-wrap: wrap
    gap: 20px 
    margin: 0 -12px
    +m
        margin: 0 -4px

.card
      flex: 1 0 45% // for large screens
      +t
         flex: 1 0 45%
      +m
         flex: 1 0 45% 
      +a
         flex: 1 0 100%    
         
      

.btns
    margin-top: 64px
    text-align: center
    +t
        margin-top: 40px
    .button
        +m
            width: 100%

.details
    flex: 1 0 45%