@import ../../../styles/helpers

.form
    position: relative
    display: flex
    width: 100%
    +m
       display: block

.input
    width: 70%
    margin-right: 5px
    height: 48px
    padding: 0 48px 0 14px
    border-radius: 50px
    background: none
    border: 2px solid $n4
    +poppins
    +caption-1
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n4
        color: $n8
    &:focus
        border-color: $n3
    +placeholder
        color: $n4
    +m
       width: 100%  
       margin-bottom: 5px

.toast-warning 
    background-color: #ff9800 /* Orange */
    color: white 
 

.btn
    width: 30%
    height: 48px
    background: $d1
    color: $white
    transition: background .2s
    svg
        fill: $n8
    &:hover
        background: darken($p1, 10)
        color: $n9
    +d    
    +m
       width: 100%    

.big
    .input
        height: 72px
        padding: 0 72px 0 22px
        border-radius: 5%
        border: 2px solid $n7
        font-size: 16px
        +placeholder
            color: $n5
        &:focus
            border-color: $n5
        +dark
            border-color: $n3
            &:focus
                border-color: $n5
    .btn
        top: 16px
        right: 16px
        bottom: 16px
        width: 40px
        height: 40px
        svg
            width: 20px
            height: 20px
        +m 
            width: 100%   