.hero,
.h1,
.h2,
.h3,
.h4
    +dm-sans
    font-weight: 600

.hero
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    +x
        font-size: 80px
    +d
        font-size: 50px

.h1
    font-size: 80px
    line-height: 1
    letter-spacing: -.02em
    +m
        font-size: 38px
        line-height: (56/48)

.h2
    font-size: 50px
    line-height: (56/48)
    letter-spacing: -.02em
    +m
        font-size: 30px
        line-height: (48/40)
        letter-spacing: -.01em
        

.h3
    font-size: 40px
    line-height: (48/40)
    letter-spacing: -.01em
    +m
        font-size: 32px
        line-height: (40/32)

.h4
    font-size: 30px
    line-height: (40/32)
    letter-spacing: -.01em
    +m
        font-size: 25px
