.container
    width: 100%
    max-width: 100%
    margin: 0 auto
    padding: 0 100px
    +x  
        padding: 0 130px
    +t
        padding: 0 90px
    +m
        padding: 0 32px